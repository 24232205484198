<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import "firebase/compat/firestore";
import { authComputed } from "@/state/helpers";

/**
 * Contacts-Profile component
 */
export default {
  page: {
    title: "Profile",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Profile",
      breadcrumbs: [
        {
          text: "Profile",
          active: true
        }
      ],
      upgradeBtnLoading: false,
      cancelBtnLoading: false,
      myCroppa: {},
    };
  },
  computed: {
    ...authComputed,
  },  
  methods: {
    async openStripePortal(action) {
      if (action === 'cancel') {
        this.cancelBtnLoading = true
      } else {
        this.upgradeBtnLoading = true
      }
      await firebase.functions().httpsCallable('getStripePortalUrl')()
        .then(res => {
          console.log('url: ' + res.data)
          window.location.href = res.data
        })
        .catch(error => {
          console.log(error.message)
        })
    },
    capitalize(word) {
      const capitalizedFirst = word[0].toUpperCase();
      const rest = word.slice(1);
      return capitalizedFirst + rest;
    },
    uploadCroppedImage() {
      this.myCroppa.generateBlob(
        blob => {
          // Get current username
          const user = firebase.auth().currentUser;
          // Create a Storage Ref w/ username
          const storageRef = firebase.storage().ref(
            user.uid + '/' + user.uid + '.jpg');
          // Upload file
          var uploadTask = storageRef.put(blob)
          uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            (snapshot) => {
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log('Upload is paused');
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log('Upload is running');
                  break;
              }
            }, 
            (error) => {
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              console.log(error.message);
            }, 
            () => {
              // Upload completed successfully, now we can get the download URL
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                console.log('File available at', downloadURL);
                // Add photoURL to auth user
                const user = firebase.auth().currentUser;
                user.updateProfile({
                  photoURL: downloadURL
                }).then(() => {
                  console.log('Profile updated.');
                }).catch((error) => {
                  console.log(error.message);
                });
                // Add photoURL to Firestore
                if (user) {
                  firebase.firestore()
                  .collection('users')
                  .doc(user.uid)
                  .update({
                    photoURL: downloadURL
                  })
                  .then(() => {
                    console.log('photoURL added to Firestore.');
                    this.$store.dispatch('auth/refreshUserData', null,  {root:true});
                  })
                  .catch(error => {
                    console.log(error)
                  })
                }
              });
            }
          );
        }
      )
    },
    onFileSizeExceeded () {
      alert('File size exceeded. Please choose a file smaller than 10 MB.')
    },
  },
};
</script>

<style scoped>
.underline { text-decoration: underline; }
.red { color: red; }
.pointer { cursor: pointer; }
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadcrumbs" />
    <div class="row">
      <div class="col-xl-4">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-3">
                  <h5 class="text-primary"></h5>
                  <p></p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img src="@/assets/images/profile-img.png" alt class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-sm-4">
                <div class="avatar-md profile-user-wid mb-4 pointer" @click="$bvModal.show('profilePicUpload')">
                  <img
                    :src=userData.photoURL
                    v-if="userData.photoURL"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                  <img
                    src="@/assets/images/users/default_light_blue.png"
                    v-else
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                </div>
                <b-modal
                  id="profilePicUpload"
                  centered
                  title="Change Profile Picture"
                  title-class="font-18 text-center"
                  content-class="text-center"
                  @ok="uploadCroppedImage"
                >
                  <croppa
                    v-model="myCroppa"
                    :width="200"
                    :height="200"
                    :accept="'image/*'"
                    :placeholder="'Choose an image'"
                    :placeholder-font-size="14"
                    :file-size-limit="10000000"
                    @file-size-exceed="onFileSizeExceeded"
                    canvas-color="#D5D8DC"
                  ></croppa>
                </b-modal>
                <h5 class="font-size-15 text-truncate">Matt R</h5>
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>

      <div class="col-xl-8">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Details</h4>
            <div class="table-responsive ">
              <table class="table table-nowrap mb-0 align-middle">
                <tbody>
                  <tr>
                    <th scope="row" style="width: 120px">Display Name</th>
                    <td>{{ this.$store.state.auth.currentUser.displayName }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Email</th>
                    <td>{{ this.$store.state.auth.currentUser.email }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Tier</th>
                    <td>
                      <div class="d-flex flex-row align-items-center">
                        <div v-if="userData.tier">{{ capitalize(userData.tier) }}</div>
                        <div class="ms-3" v-if="!(userData.tier == 'pro')">
                          <b-button
                            variant="success"
                            size="sm"
                            :disabled="upgradeBtnLoading"
                            @click="openStripePortal('upgrade')"
                          >
                            Change Plan
                          </b-button>
                        </div>
                        <div class="ms-1">
                          <b-spinner small v-if="upgradeBtnLoading"></b-spinner>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" style="width: 120px"></th>
                    <td>
                      <div class="d-flex flex-row align-items-center">
                        <div>
                          <a href="#" class="underline red" @click="openStripePortal('cancel')">
                            Cancel Subscription
                          </a>
                        </div>
                        <div class="ms-1">
                          <b-spinner small v-if="cancelBtnLoading"></b-spinner>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>